import { useEffect, useRef,useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import Modal from 'react-bootstrap/Modal';

import "./TSCommon.css";
import "./TSLogin.css";
import { GetAuthorize, GetUserMe } from "../../api/ApiGetUser";
import {PrintConsole, getApiUrl, sleep, AlertError} from "../../function/common";
import axios from "axios";
import sha256 from "sha256";

export default function TSLogin() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const autoChecked = useRef();

    const  clickAutoLogin = (checked) => {
        autoChecked.current = checked;
    }

    function login() {
        return new Promise( async function(resolve, reject) {
            let obj = { 
                "loginId"  : document.getElementById('Id').value,
                "password" : sha256(document.getElementById('Password').value)
            }
            console.log(obj);
            await axios.post( 
                getApiUrl('v1/auth/login'),
                obj, 
                axios.defaults.headers.common['ContentType'] = "application/json;"
            ).then( 
                function(response){
                    if(response.status === 200){
                        if(autoChecked.current == true){
                            localStorage.setItem('autoLogin',true);
                        }else{
                            localStorage.setItem('autoLogin',false);
                        }
                        localStorage.setItem('accessToken', response.data.loginToken.accessToken);
                        navigate('/tsmain');
                    }
                }
            ).catch(function(error){
                // AlertError(error);
                if(error && error.response && error.response.data && error.response.data.code ==-9) {
                    setShow(true)
                }else{
                    AlertError(error);
                }
            })
        });
    };

    useEffect(() => {
        if(localStorage.getItem('autoLogin') == 'true'){
            if(localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null){
                axios.get(
                    getApiUrl('v1/kitchen/me'),
                    axios.defaults.headers.common['ContentType'] = "application/json;",
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
                ).then(
                    function (response) {
                        if (response.status === 200) {
                            navigate('/tsmain');
                        }
                    }
                ).catch(function (error) {
                    // console.log('error');
                })
            }
        }

    }, []);

    if (loading)
        return (
            <div className="container" style={{ position: "absolute", left: "50%", top: "50%", marginLeft: "-18px" }}>
                <Spinner animation="border" />
            </div>
        );

    return (
        <>
            <div className="TSto">
                <div className="TSLoginPage">
                    <div className="container g-0 position-absolute start-50 top-50 translate-middle w-100">
                        <div className="Inner m-auto">
                            <div className="LogoImg text-center">
                                <img src="https://www.flexdaycdn.net/public/images/ticket_store/login-logo.png" alt="" />
                                <p className="txt mb-0">
                                    다솜식당 전용
                                </p>
                            </div>
                            <div className="LoginBox">
                                <div className="row g-0">
                                    <input type="text" className="form-control InputType " placeholder="아이디" required="required" id='Id' name='Id'
                                        autoComplete="off" />
                                </div>
                                <div className="row g-0 mb-0">
                                    <input type="password" className="form-control InputType" placeholder="비밀번호" required="required" id='Password' name='Password'
                                        autoComplete="off" />
                                </div>
                                <div className="auto_logoin form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" onClick={(e) =>clickAutoLogin(e.target.checked)}/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        자동 로그인
                                    </label>
                                </div>
                                <button type="button" className="btn btn-default2 LoginSubmit" onClick={ () => {login();}}> 로그인</button>
                                <p style={{color:'#d3d3d3',textAlign:'center'}}>ver { process.env.REACT_APP_VERSION}</p>
                            </div>
                        </div>
                    </div>


                    {/* 아이디/비밀번호 불일치 Modal */}
                    <Modal
                    show={show}
                    onHide={handleClose}
                    keyboard={false}
                    size="sm"
                    className="demoModal TSto"
                    centered
                    >
                        <Modal.Body className="pb-4-5 text-center">
                            아이디 또는 비밀 번호가 틀렸습니다. <br />
                            다시 입력해주세요.
                        </Modal.Body>
                        <Modal.Header className="text-center">
                            <Modal.Title>
                                계정 정보 확인
                            </Modal.Title>
                        </Modal.Header>                        
                        <Modal.Footer className="d-flex flex-nowrap">
                            <button type="button" className="btn btn-clear btn-modal-primary w-100" onClick={handleClose}>
                                확인
                            </button>
                        </Modal.Footer>
                    </Modal> 

                </div>
            </div>
        </>
    );
}
