import {getApiUrl, getday, getTime} from "../../../function/common";
import { useEffect, useRef, useState } from "react";
import HeaderTime from "./HeaderTime";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import CommonHeader from "./CommonHeader";
import {useNavigate} from "react-router-dom";

export default function CommonSide({pageName,waiting,cooking,requested,completed,cancelled}){
    const navigate = useNavigate();


    return (
        <>
            <div className="SideNavbar">
                <div className={pageName=="TSStoreNew"?"side-item acitve":"side-item"} onClick={() => (navigate('/tsnew'))}>
                    <div className="number">
                        <span>{waiting && waiting.totalCount || 0}</span>
                    </div>
                    <div className="txt">
                        신규 주문
                    </div>
                </div>
                <div className={pageName=="TSStoreProcee"?"side-item acitve":"side-item"} onClick={() => (navigate('/tsprocess'))}>
                    <div className="number">
                        <span>{cooking && cooking.totalCount || 0}</span>
                    </div>
                    <div className="txt">
                        조리중
                    </div>
                </div>
                <div className={pageName=="TSStorePickProcee"?"side-item acitve":"side-item"} onClick={() => (navigate('/tspick'))}>
                    <div className="number">
                        <span>{requested && completed&& (requested.totalCount + completed.totalCount) || 0}</span>
                    </div>
                    <div className="txt">
                        조리 완료
                    </div>
                </div>
                {/* <div className={pageName=="TSStorePickDone"?"side-item acitve":"side-item"} onClick={() => (navigate('/tspickdone'))}>
                    <div className="number">
                        <span>{completed && completed.totalCount || 0}</span>
                    </div>
                    <div className="txt">
                        전달 완료
                    </div>
                </div> */}
                <div className={pageName=="TSStoreRefuse"?"side-item acitve":"side-item"} onClick={() => (navigate('/tsrefuse'))}>
                    <div className="number">
                        <span>{cancelled && cancelled.totalCount || 0}</span>
                    </div>
                    <div className="txt">
                        주문 거절
                    </div>
                </div>
            </div>
        </>
    )

}