import {getApiUrl} from "../../../function/common";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import CommonHeader from "./CommonHeader";
import CommonSide from "./CommonSide";
import {Spinner} from "react-bootstrap";
import TSStoreNew from "../TSStoreNew";
import TSStoreRefuse from "../TSStoreRefuse";
import TSStoreProcee from "../TSStoreProcee";
import TSStorePickProcee from "../TSStorePickProcee";
import TSStorePickDone from "../TSStorePickDone";
import TSStoreClose from "../TSStoreClose";
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";
import TSStoreTest from "../TSStoreTest";

export default function CommonMain({pageName}){
    const [loading, setLoading] = useState(false);


    const [showToast, setShowToast] = useState(false);
    const [waiting, setWaiting] = useState();
    const interval = useRef();
    //사운드 플레이 중인지
    const playingAudio = useRef(false);
    const isOpen = useRef(false);

    function getWaiting() {
        return new Promise(async function (resolve, reject) {
            let obj = {
                smartOrderStatus: 'WAITING_ACCEPT'
            }
            const response = await axios.get(
                getApiUrl('v1/kitchen/me/smart-orders'),
                {params: obj},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8",
            ).then(
                function (response) {
                    if (response.status === 200) {
                        if(response.data.totalCount>0){
                            setLastNewSmartOrderId(response);
                        }
                        setWaiting(response.data);
                    }
                }
            ).catch(function (error) {
                // console.log('error');
            })
        })
    };

    const [cooking, setCooking] = useState();

    function getCooking() {
        return new Promise(async function (resolve, reject) {
            let obj = {
                smartOrderStatus: 'COOKING'
            }
            const response = await axios.get(
                getApiUrl('v1/kitchen/me/smart-orders'),
                {params: obj},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8",
            ).then(
                function (response) {
                    if (response.status === 200) {
                        setCooking(response.data);
                    }
                }
            ).catch(function (error) {
                // console.log('error');
            })
        })
    };

    const [requested, setRequested] = useState();

    function getRequested() {
        return new Promise(async function (resolve, reject) {
            let obj = {
                smartOrderStatus: 'PICK_UP_REQUESTED'
            }
            const response = await axios.get(
                getApiUrl('v1/kitchen/me/smart-orders'),
                {params: obj},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8",
            ).then(
                function (response) {
                    if (response.status === 200) {
                        setRequested(response.data);
                    }
                }
            ).catch(function (error) {
                // console.log('error');
            })
        })
    };

    const [completed, setCompleted] = useState();

    function getCompleted() {
        return new Promise(async function (resolve, reject) {
            let obj = {
                smartOrderStatus: 'PICK_UP_COMPLETED'
            }
            const response = await axios.get(
                getApiUrl('v1/kitchen/me/smart-orders'),
                {params: obj},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8",
            ).then(
                function (response) {
                    if (response.status === 200) {
                        setCompleted(response.data);
                    }
                }
            ).catch(function (error) {
                // console.log('error');
            })
        })
    };

    const [cancelled, setCancelled] = useState();

    function getCancelled() {
        return new Promise(async function (resolve, reject) {
            let obj = {
                smartOrderStatus: 'CANCELLED'
            }
            const response = await axios.get(
                getApiUrl('v1/kitchen/me/smart-orders'),
                {params: obj},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8",
            ).then(
                function (response) {
                    if (response.status === 200) {
                        setCancelled(response.data);
                    }
                }
            ).catch(function (error) {
                // console.log('error');
            })
        })
    };

    const [none, setNone] = useState();

    function getNone() {
        return new Promise(async function (resolve, reject) {
            let obj = {
                smartOrderStatus: 'NONE'
            }
            const response = await axios.get(
                getApiUrl('v1/kitchen/me/smart-orders'),
                {params: obj},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8",
            ).then(
                function (response) {
                    if (response.status === 200) {
                        setNone(response.data);
                    }
                }
            ).catch(function (error) {
                // console.log('error');
            })
        })
    };
    const [myKitchen, setMyKitchen] = useState({kitchenName:""});

    function getMyKitchen() {
        return new Promise(async function (resolve, reject) {
            const response = await axios.get(
                getApiUrl('v1/kitchen/me'),
                axios.defaults.headers.common['ContentType'] = "application/json;",
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
            ).then(
                function (response) {
                    if (response.status === 200) {
                        isOpen.current = response.data.isOpen;
                        setMyKitchen(response.data);
                    }
                    setLoading(false);
                }
            ).catch(function (error) {
                // console.log('error');
            })
        })
    };

    function setLastNewSmartOrderId(response){
        localStorage.setItem('lastNewSmartOrderId',response.data.data[response.data.data.length-1].smartOrderId)
    }

    function checkNewOrder() {
        return new Promise(async function (resolve, reject) {
            let obj = {
                smartOrderStatus: 'WAITING_ACCEPT',
                cursorId: localStorage.getItem('lastNewSmartOrderId')
            }
            const response = await axios.get(
                getApiUrl('v1/kitchen/me/smart-orders'),
                {params: obj},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8",
            ).then(
                function (response) {
                    if (response.status === 200) {
                        if(response.data.totalCount>0){
                            console.log("신규 주문 있음");
                            playAudio();
                        }else{
                            console.log("신규 주문 없음")
                        }
                    }
                }
            ).catch(function (error) {
                // console.log('error');
            })
        })
    };

    function healthChecker() {
        return new Promise(async function (resolve, reject) {
            let obj = {
            }
            const response = await axios.post(
                getApiUrl('v1/kitchen/me/health'),
                {params: obj},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8",
            ).then(
                function (response) {
                    if (response.status === 200) {
                    }
                }
            ).catch(function (error) {
                // console.log('error');
            })
        })
    };

    function playAudio(){
        document.getElementById('audio').play();

        // if(playingAudio.current){
        //
        // }else{
        //     playingAudio.current = true;
        //     setTimeout(function(){
        //         playingAudio.current = false;
        //     },2000);
        //
        // }
    }

    function apiReload(){
        getWaiting();
        getCooking();
        getRequested();
        getCompleted();
        getCancelled();
    }

    useEffect(() => {
        // 메뉴 상태별 로드
        apiReload();
        getMyKitchen();




    }, []);

    useEffect(()=>{
        interval.current = setInterval(() => {
            try {
                if(myKitchen.isOpen){
                    checkNewOrder();
                    apiReload();
                    healthChecker();
                }
            } catch (e) {
                console.log(e)
            }
        }, 5*1000);
        return () => {
            clearInterval(interval.current);
        }
    },[myKitchen])


    if (loading)
        return (
            <div className="container" style={{position: "absolute", left: "50%", top: "50%", marginLeft: "-18px"}}>
                <Spinner animation="border"/>
            </div>
        );

    function getPage(){
        switch(pageName){
            case 'TSStoreNew' :
                return(<> <TSStoreNew waiting={waiting} apiReLoad={apiReload} setShowToast={setShowToast} showToast={showToast}></TSStoreNew></>)
            case 'TSStoreProcee':
                return(<> <TSStoreProcee cooking={cooking} apiReLoad={apiReload} setShowToast={setShowToast} showToast={showToast}></TSStoreProcee></>)
            case 'TSStorePickProcee':
                return(<> <TSStorePickProcee requested={requested} apiReLoad={apiReload} completed={completed}></TSStorePickProcee></>)
            case 'TSStorePickDone':
                return(<> <TSStorePickDone completed={completed} apiReLoad={apiReload}></TSStorePickDone></>)
            case 'TSStoreRefuse':
                return(<> <TSStoreRefuse cancelled={cancelled} apiReLoad={apiReload}></TSStoreRefuse></>)
            case 'TSStoreTest':
                return(<> <TSStoreTest></TSStoreTest></>)
            default:
                return(<> <TSStoreRefuse cancelled={cancelled} apiReLoad={apiReload}></TSStoreRefuse></>)
        }
    }

    return (
        <>
            <div className="TSto mw-100">
                <div className={myKitchen.isOpen?"TStorePage StoreRefuse":"TStorePage StoreClose"}>
                    <div className="container-fluid g-0 vh-100">
                        <CommonHeader myKitchen={myKitchen} setMyKitchen={setMyKitchen}/>
                        {
                            myKitchen.isOpen ==true?
                                <div className="StoWrap">
                                    <CommonSide pageName={pageName} waiting={waiting} cooking={cooking} requested={requested} completed={completed} cancelled={cancelled} />
                                    {getPage()}
                                </div>
                                :
                                <TSStoreClose setMyKitchen={setMyKitchen} />
                        }
                    </div>
                    <ToastContainer
                        className="custom"
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        icon={({theme,type})=><img alt="" src="https://www.flexdaycdn.net/public/images/ticket_store/check_icon.png"
                                                   className="img-fluid icon" />}
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>

            <audio id="audio" src="https://dev.flexdaycdn.net/public/images/bell_320kbps_track8.mp3"></audio>
        </>
    )

}