import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Spinner} from "react-bootstrap";

import "./TSCommon.css";
import "./TSStore.css";
import {maskingName, maskingPhoneNumber, setCommas, tableDateFormat} from "../../function/common";
import {toast} from "react-toastify";

export default function TSStoreTest() {

    function click(){
        toast('주문번호 56 - 픽업을 완료하였습니다');
    }


    return (
        <>
            <div className="ContentsWrap no-order">
                <div className="no_order_txt" onClick={click}>
                    거절된 주문이 없습니다
                </div>
            </div>
        </>
    );
}
