import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import Error from './pages/Error';
import NotFound from './pages/NotFound';
import NotFound2 from './pages/NotFound2';
import TSLogin from './pages/Order/TSLogin';
import TSStoreProcee from './pages/Order/TSStoreProcee';
import TSStoreNew from './pages/Order/TSStoreNew';
import TSStorePickProcee from './pages/Order/TSStorePickProcee';
import TSStorePickDone from './pages/Order/TSStorePickDone';
import TSStoreRefuse from './pages/Order/TSStoreRefuse';
import CommonMain from "./pages/Order/component/CommonMain";


function App() {

  if(process.env.NODE_ENV !== 'production'){
    console.log('domainType : ' + localStorage.getItem('domainType'));
  }

  

  return (
    <div className="App">
      <header className="App-header">
        
          <Router>
            
            <Routes>
              {/* 테블릿 로그인 */}
              <Route path="/login" element={<TSLogin />}></Route>
              
              {/* 진행중 */}
              <Route path="/tsmain" element={<CommonMain pageName={"TSStoreNew"}/>}></Route>
              {/* 신규 */}
              <Route path="/tsnew" element={<CommonMain pageName={"TSStoreNew"}/>}></Route>
              {/* 진행중 */}
              <Route path="/tsprocess" element={<CommonMain pageName={"TSStoreProcee"}/>}></Route>
              {/* 픽업진행 */}
              <Route path="/tspick" element={<CommonMain pageName={"TSStorePickProcee"}/>}></Route>
              {/* 픽업완료 */}
              <Route path="/tspickdone" element={<CommonMain pageName={"TSStorePickDone"}/>}></Route>
              {/* 주문거절 */}
              <Route path="/tsrefuse" element={<CommonMain pageName={"TSStoreRefuse"} />}></Route>
              {/* 테스트 */}
              <Route path="/tstest" element={<CommonMain pageName={"TSStoreTest"} />}></Route>

              <Route path="/" element={<TSLogin/>}></Route>
              
              <Route path="/system" element={<NotFound2/>}></Route>
              <Route path="/error" element={<Error/>}></Route>
              <Route path="/404" element={<NotFound/>}></Route>
              <Route path="*" element={<NotFound/>}></Route>
            </Routes>
            
          </Router>
      </header>
    </div>
  );
}

export default App;
