import "../TSCommon.css";
import "../TSStore.css";
import { useEffect, useRef, useState,memo } from "react";
import axios from "axios";
import {AlertError, getApiUrl} from "../../../function/common";
import {toast} from "react-toastify";

export default function PaperTicketAddModal({ apiReload}) {
    // 모달 보이기
    const [show, setShow] = useState(false);
    const [paperTicket, setPaperTicket] = useState(new Map());
    const totalCount = useRef(0);

    function getTotalCount(){
        return Array.from(paperTicket).map(([key,value]) =>value.countOfProducts).reduce((prev,curr)=> prev+ curr,0);
    }

    function minusMap( product ){
        const countOfProducts = paperTicket.get(product.productId).countOfProducts-1
        --totalCount.current;
        if(countOfProducts<1){
            setPaperTicket((prev)=> {const newMap = new Map(prev);newMap.delete(product.productId);return newMap})
        }else{
            setPaperTicket((prev)=> new Map(prev).set(product.productId,{...product, 'countOfProducts':countOfProducts}))
        }
    }

    function addMap( product ){
        ++totalCount.current;
        const listInMap = {...product, 'countOfProducts':1};
        if(paperTicket.has(product.productId)){
            setPaperTicket((prev)=> new Map(prev).set(product.productId,{...product, 'countOfProducts':paperTicket.get(product.productId).countOfProducts+1}))
        }else{
            setPaperTicket((prev)=> new Map(prev).set(product.productId, listInMap))
        }
    }


    useEffect(() => {
        if(show == true){
            getProducts()
        }
    }, [show]);

    const [products, setProducts] = useState();
    function getProducts() {
        return new Promise( async function(resolve, reject) {
            let obj = {
                smartOrderStatus : 'WAITING_ACCEPT'
            }
            const response = await axios.get(
                getApiUrl('v1/kitchen/me/products'),
                {params : obj},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8",
            ).then(
                function(response){
                    console.log(response.data)
                    if(response.status === 200){
                        setProducts(response.data);
                    }
                }
            ).catch(function(error){
                // console.log('error');
            })
        })
    };

    function addPaperTicket(){

        const variables = {
            products: Array.from(paperTicket).map(([key,value])=>{return paperTicket.get(key)})
        }

        axios.post(
            getApiUrl("v1/kitchen/me/smart-orders"),
                variables,
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                toast('주문번호 '+response.data.orderNumber+'-'+response.data.orderName+' 신규 주문을 등록하였습니다.');
                setShow(false);
                setPaperTicket(new Map());
                apiReload();
            }
        ).catch(function(error){
            console.log(error)
            AlertError(error);
        });
    }



    return(
        <>
            {/* 종이 식권 추가 버튼 */}
            <button onClick={()=>setShow(true)} className="btn btn-clear paperTicket_add">
                <img alt="" src="https://www.flexdaycdn.net/public/images/ticket_store/icon-plus.png"
                     className="img-fluid" />
                종이식권을 받았어요
            </button>

            {/* 종이 식권 추가 모달 */}
            {/* 임시로 d-none 추가해서 숨김처리 */}
            <div className={show?'paperTicket_addBox':'paperTicket_addBox d-none'} >
                <img alt="" src="https://www.flexdaycdn.net/public/images/ticket_store/modal-close.png" onClick={()=>{setPaperTicket(new Map());setShow(false);}}
                     className="img-fluid modal_close" />
                <div className="paper_orderList">
                    <p className="desc_txt">
                        주문 리스트에 종이식권 순서를 추가합니다 <br />
                        준비하실 메뉴를 선택해주세요
                    </p>
                    <div className="listbox">
                        {products && products.map( (row, idx) => (
                            <div key={row.productId} className="item form-check" onClick={()=>addMap(row)}>
                                <input className="form-check-input" type="checkbox" name="addMenu" id={row.productId} />
                                <label className="form-check-label" htmlFor="add_menu1">
                                    {row.productName}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                {/* 종이식권 주문 추가한 경우 */}
                {paperTicket.size>0?
                <div className="paper_addresult">
                    <p className="tit_txt">
                        종이식권 주문 추가
                    </p>
                    <div className="sel_menubox">
                        <div className="sub_txt">
                            선택 메뉴
                        </div>
                        <div className="sel_menu_wrap">
                            {paperTicket.size>0 && Array.from(paperTicket).map(([key,value])=>{
                                return(<div key={key} className="sel_menu">
                                            <div className="prd-name">
                                                {paperTicket.get(key).productName}
                                            </div>
                                            <div className="controls">
                                                <button className="btn btn-clear btn-minus" onClick={()=>minusMap( paperTicket.get(key))}>
                                                    <img alt=""
                                                         src="https://www.flexdaycdn.net/public/images/ticket_store/btn-minus.png"
                                                         className="img-fluid" />
                                                </button>
                                                <input type="text" className="form-control count_value" value={paperTicket.get(key).countOfProducts}  onChange={()=>null}/>
                                                <button className="btn btn-clear btn-plus" onClick={()=>addMap( paperTicket.get(key))}>
                                                    <img alt=""
                                                         src="https://www.flexdaycdn.net/public/images/ticket_store/btn-plus.png"
                                                         className="img-fluid" />
                                                </button>
                                            </div>
                                        </div>)
                            })}
                        </div>
                    </div>
                    <div className="sel_total">
                        <div className="total_count">
                            총 <span>{getTotalCount()}</span>개
                        </div>
                        <button className="btn btn-addpaper" onClick={() => addPaperTicket()}>
                            추가
                        </button>
                    </div>
                </div>
                :
                <div className="paper_addresult blank">
                    <p className="tit_txt">
                        종이식권 주문 추가
                    </p>
                    <div className="sel_menubox">
                        <div className="blank">
                            선택한 메뉴가 없습니다
                        </div>
                    </div>
                    <div className="sel_total">
                        <div className="total_count">
                            총 <span>{0}</span>개
                        </div>
                        <button className="btn btn-addpaper">
                            추가
                        </button>
                    </div>
                </div>
                    }
            </div>
            {/* 종이 식권 추가 모달 */}
        </>
    )

}