import {getday, getTime, tableDateFormat} from "../../../function/common";
import { useEffect, useRef, useState } from "react";

export default function TimeChecker({targetDate, isNormalFormat}){
    let [time, setTime] = useState(new Date());
    const interval = useRef();


    function replaceTime(date){
        const start = new Date(date);
        const end = time;

        const diff = (end - start) / 1000;

        const times = [
            { name: '년', milliSeconds: 60 * 60 * 24 * 365 },
            { name: '개월', milliSeconds: 60 * 60 * 24 * 30 },
            { name: '일', milliSeconds: 60 * 60 * 24 },
            { name: '시간', milliSeconds: 60 * 60 },
            { name: '분', milliSeconds: 60 },
        ];

        for (const value of times) {
            const betweenTime = Math.floor(diff / value.milliSeconds);

            if (betweenTime > 0) {
                return `${betweenTime}${value.name} 전`;
            }
        }
        return '방금 전';
    }

    useEffect(() => {
        interval.current = setInterval(() => {
            setTime(new Date());
        }, 60*1000);

        return () => {
            clearInterval(interval.current);
        }
    }, []);

    return (
        <>
            {isNormalFormat==true?tableDateFormat(targetDate):replaceTime(targetDate)}
        </>
    )

}