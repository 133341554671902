import {getday, getTime} from "../../../function/common";
import { useEffect, useRef, useState } from "react";

export default function HeaderTime(){
    let [time, setTime] = useState(new Date());
    const interval = useRef();

    useEffect(() => {
        interval.current = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => {
            clearInterval(interval.current);
        }
    }, []);

    return (
        <>
            {time.getFullYear()}년 {time.getMonth()+1}월 {time.getDate()}일 {getday(time.getDay())} {getTime(time)}
        </>
    )

}