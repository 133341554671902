import {useEffect, useState} from "react";
import {useNavigate, Link} from "react-router-dom";
import {Spinner} from "react-bootstrap";


import "./TSCommon.css";
import "./TSStore.css";
import axios from "axios";
import {getApiUrl} from "../../function/common";

export default function TSStoreClose({setMyKitchen}) {
    const navigate = useNavigate();

    function openKitchen() {
        return new Promise(async function (resolve, reject) {

            const response = await axios.post(
                getApiUrl('v1/kitchen/me/start'),
                {params: null},
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8",
            ).then(
                function (response) {
                    if (response.status === 200) {
                        setMyKitchen(response.data);
                    }
                }
            ).catch(function (error) {
                console.log('error');
            })
        })
    };


    return (
        <>
            <div className="StoWrap">
                <div className="ContentsWrap no-order">
                    <div className="no_order_txt">
                        현재 주방 종료 상태입니다 <br/>
                        주방을 다시 오픈하시려면 아래 버튼을 눌러주세요

                        <button type="button" className="btn btn-default2 btn-openStore" onClick={openKitchen}>주방 오픈</button>
                    </div>
                </div>
            </div>
        </>
    );
}
