import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Spinner} from "react-bootstrap";

import "./TSCommon.css";
import "./TSStore.css";
import {
    AlertError,
    getApiUrl,
    maskingName,
    maskingPhoneNumber,
    setCommas,
    tableDateFormat
} from "../../function/common";
import axios from "axios";
import TimeChecker from "./component/TimeChecker";
import {toast} from "react-toastify";

export default function TSStorePickProcee({requested,apiReLoad, completed}) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const toastText = useRef();
    const selectedSmartOrderId = useRef(0);
    const selectedOrderNumber = useRef(0);
    const [showDefault, setShowDefault] = useState(true);


    const [selectedItems, setSelectedItems] = useState([]);

    const handleClickCss = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };


    function requestToPickup(smartOrderId,orderNumber){
        selectedSmartOrderId.current = smartOrderId;
        selectedOrderNumber.current = orderNumber
        toastText.current = "주문번호 "+selectedOrderNumber.current+" - 픽업을 재요청 하였습니다."

        axios.post(
            getApiUrl("v1/kitchen/me/smart-orders/"+smartOrderId+"/pickup"),
            // {params : obj},
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    toast(toastText.current);
                    apiReLoad();
                }
            }
        ).catch(function(error){
            AlertError(error);
        });

    }

    function completeToPickup(smartOrderId,orderNumber){
        selectedSmartOrderId.current = smartOrderId;
        selectedOrderNumber.current = orderNumber
        toastText.current = "주문번호 "+selectedOrderNumber.current+" - 픽업을 완료하였습니다";
        axios.post(
            getApiUrl("v1/kitchen/me/smart-orders/"+smartOrderId+"/pickup-complete"),
            // {params : obj},
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    toast(toastText.current);
                    apiReLoad();
                    setShowDefault(false);
                }
            }
        ).catch(function(error){
            AlertError(error);
        });

    }

    useEffect(()=>{
        const isDefault = new URLSearchParams(location.search).get('isDefault');
        if(isDefault !== null && isDefault ==='false'){
            setShowDefault(false);
        }
    },[])


    if (loading)
        return (
            <div className="container" style={{ position: "absolute", left: "50%", top: "50%", marginLeft: "-18px" }}>
                <Spinner animation="border" />
            </div>
        );

    return (
        <>


                        {/* 픽업 진행중 없는 경우 */}
                        {/* 임시로 d-none 추가해서 숨김처리 */}

                        {/*{requested && requested.totalCount ==0 &&*/}
                        {/*    <div className="ContentsWrap no-order">*/}
                        {/*        <div className="no_order_txt">*/}
                        {/*            진행중인 픽업이 없습니다*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*}*/}

                        {/* 픽업 진행중 있는 경우 */}
                        <div className={requested && requested.totalCount==0 && completed && completed.totalCount==0?"ContentsWrap no-order":"ContentsWrap"}>
                        {requested && requested.totalCount == 0 && completed && completed.totalCount==0  ?
                            <div className="no_order_txt">
                                조리 완료된 주문이 없습니다
                            </div>
                            :

                                <div className="order_list d-flex">

                                    <div className="sorting_btnbox d-flex">
                                        <button type="button" className={showDefault==true?"btn btn-light on":"btn btn-light"} onClick={() => setShowDefault(true)}>
                                            안가져간 주문({requested && requested.totalCount})
                                        </button>
                                        <button type="button" className={showDefault==false?"btn btn-light on":"btn btn-light"} onClick={() => setShowDefault(false)}>
                                            가져간 주문({completed && completed.totalCount})
                                        </button>
                                    </div>
                                    { showDefault === true ?
                                        requested && requested.data.map( (row, idx) => (
                                            <div className={selectedItems.includes(row)?'order_item':'order_item collapsed'} key={row.orderNumber} >
                                                <div className="order_top d-flex ">
                                                    <div className={selectedItems.includes(row)?"order_titbox d-flex collapsed":"order_titbox d-flex"} data-bs-toggle="collapse"
                                                         onClick={(state) => handleClickCss(row)}
                                                         data-bs-target={"#Order_"+idx}>
                                                        <div className="order_num">
                                                            주문번호
                                                            <span className="num">{row.orderNumber}</span>
                                                        </div>
                                                        <div className="order_name">
                                                            {row.orderName}
                                                        </div>
                                                    </div>

                                                    <div className="order_statebox d-flex">
                                                        <div className="order_time">
                                                            조리 완료
                                                            {/* <TimeChecker targetDate={row.acceptRequestedAt}/> */}
                                                        </div>
                                                        <div className="order_picktime">
                                                            {/* 픽업 요청  */}
                                                            {/* <TimeChecker targetDate={row.pickupRequestedAt}/> */}
                                                            {tableDateFormat(row.acceptRequestedAt)}
                                                        </div>
                                                        {/* <div className="order_btnbox d-flex">
                                                            <button type="button" className="btn btn-default2 order_repickup" onClick={()=>requestToPickup(row.smartOrderId, row.orderNumber)}>
                                                                픽업 재요청
                                                            </button>
                                                            <button type="button" className="btn  btn-secondary3 order_pickdone" onClick={()=> completeToPickup(row.smartOrderId, row.orderNumber)}>
                                                                전달완료
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                    <div className="order_arrow" onClick={(state) => handleClickCss(row)}
                                                         data-bs-toggle="collapse" data-bs-target={"#Order_" + idx}>
                                                        <img src="https://www.flexdaycdn.net/public/images/ticket_store/order_arrow_blue.png"
                                                            alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div id={"Order_"+idx} className="order_contbox collapse">
                                                    <div className="order_cont_menulist">
                                                        <div className="title">
                                                            주문 메뉴
                                                        </div>
                                                        <div className="prd_info">
                                                            <div className="prd_name">
                                                                {row.orderName}
                                                            </div>
                                                            <div className="prd_count">
                                                                1개
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="order_cont_state">
                                                        <button type="button" class="btn  btn-secondary3 order_pickdone" onClick={() => completeToPickup(row.smartOrderId, row.orderNumber)}>
                                                            전달 완료
                                                        </button>
                                                    </div>
                                                    <div className="order_cont_infobox">
                                                        <div className="orderbox">
                                                            <p className="title">
                                                                주문 정보
                                                            </p>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    주문 일시
                                                                </div>
                                                                <div className="desc">
                                                                    {tableDateFormat(row.acceptRequestedAt)}
                                                                </div>
                                                            </div>
                                                        </div>                                                       
                                                        <div className="paybox">
                                                            <p className="title">
                                                                결제 정보
                                                            </p>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    결제 일시
                                                                </div>
                                                                <div className="desc">
                                                                    {tableDateFormat(row.paidAt)}
                                                                </div>
                                                            </div>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    결제 금액
                                                                </div>
                                                                <div className="desc">
                                                                    {setCommas(row.paymentPrice)+"원"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="userbox">
                                                            <p className="title">
                                                                주문자 정보
                                                            </p>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    이름
                                                                </div>
                                                                <div className="desc">
                                                                    {maskingName(row.customerName==null?row.nonUserCustomerName:row.customerName)}
                                                                </div>
                                                            </div>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    연락처
                                                                </div>
                                                                <div className="desc">
                                                                    {maskingPhoneNumber(row.phoneNumber==null?row.nonUserPhoneNumber:row.phoneNumber)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        )
                                    :
                                        completed && completed.data.map( (row, idx) => (
                                                row.smartOrderType.code === 1 ?
                                                    <div className={selectedItems.includes(row)?'order_item':'order_item collapsed'} key={row.orderNumber} >
                                                        <div className="order_top d-flex">
                                                            <div className={selectedItems.includes(row)?"order_titbox d-flex collapsed":"order_titbox d-flex"} data-bs-toggle="collapse"
                                                                 onClick={(state) => handleClickCss(row)}
                                                                 data-bs-target={"#Order_"+idx}>
                                                                <div className="order_num">
                                                                    주문번호
                                                                    <span className="num">{row.orderNumber}</span>
                                                                </div>
                                                                <div className="order_name">
                                                                    {row.orderName}
                                                                </div>
                                                            </div>

                                                            <div className="order_statebox d-flex">
                                                                <div className="order_time">
                                                                    전달완료
                                                                </div>
                                                                <div className="order_picktime2">
                                                                    <TimeChecker targetDate={row.pickupCompletedAt} isNormalFormat={true}/>
                                                                </div>
                                                            </div>
                                                            <div className="order_arrow" onClick={(state) => handleClickCss(row)}
                                                                 data-bs-toggle="collapse" data-bs-target={"#Order_" + idx}>
                                                                <img
                                                                    src="https://www.flexdaycdn.net/public/images/ticket_store/order_arrow_blue.png"
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                        </div>
                                                        <div id={"Order_"+idx} className="order_contbox collapse">
                                                            <div className="order_cont_menulist">
                                                                <div className="title">
                                                                    주문 메뉴
                                                                </div>
                                                                <div className="prd_info">
                                                                    <div className="prd_name">
                                                                        {row.orderName}
                                                                    </div>
                                                                    <div className="prd_count">
                                                                        1개
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="order_cont_infobox">
                                                                <div className="orderbox">
                                                                    <p className="title">
                                                                        주문 정보
                                                                    </p>
                                                                    <div className="cont d-flex">
                                                                        <div className="sub">
                                                                            주문 일시
                                                                        </div>
                                                                        <div className="desc">
                                                                            {tableDateFormat(row.acceptRequestedAt)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="paybox">
                                                                    <p className="title">
                                                                        결제 정보
                                                                    </p>
                                                                    <div className="cont d-flex">
                                                                        <div className="sub">
                                                                            결제 일시
                                                                        </div>
                                                                        <div className="desc">
                                                                            {tableDateFormat(row.paidAt)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="cont d-flex">
                                                                        <div className="sub">
                                                                            결제 금액
                                                                        </div>
                                                                        <div className="desc">
                                                                            {setCommas(row.paymentPrice)+"원"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="userbox">
                                                                    <p className="title">
                                                                        주문자 정보
                                                                    </p>
                                                                    <div className="cont d-flex">
                                                                        <div className="sub">
                                                                            이름
                                                                        </div>
                                                                        <div className="desc">
                                                                            {maskingName(row.customerName==null?row.nonUserCustomerName:row.customerName)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="cont d-flex">
                                                                        <div className="sub">
                                                                            연락처
                                                                        </div>
                                                                        <div className="desc">
                                                                            {maskingPhoneNumber(row.phoneNumber==null?row.nonUserPhoneNumber:row.phoneNumber)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={selectedItems.includes(row)?'order_item':'order_item collapsed'} key={row.orderNumber} >
                                                        <div className="order_top d-flex ">
                                                            <div className={selectedItems.includes(row)?"order_titbox d-flex collapsed":"order_titbox d-flex"} data-bs-toggle="collapse"
                                                                 onClick={(state) => handleClickCss(row)}
                                                                 data-bs-target={"#Order_" + idx}>
                                                                <div className="order_num">
                                                                    주문번호
                                                                    <span className="num">{row.orderNumber}</span>
                                                                </div>
                                                                <div className="order_device">
                                                                    <img
                                                                        src="https://www.flexdaycdn.net/public/images/ticket_store/tag.png"
                                                                        alt="" className="img-fluid"/>
                                                                    <div className="txt">
                                                                        식권 발매기에서 결제된 상품입니다
                                                                    </div>
                                                                </div>
                                                                <div className="order_name">
                                                                    {row.orderName}
                                                                </div>
                                                            </div>
                                                            <div className="order_statebox d-flex">
                                                                <div className="order_time">
                                                                    전달완료
                                                                </div>
                                                                <div className="order_picktime2">
                                                                    <TimeChecker targetDate={row.pickupCompletedAt}/>
                                                                </div>
                                                            </div>
                                                            <div className="order_arrow" onClick={(state) => handleClickCss(row)}
                                                                 data-bs-toggle="collapse" data-bs-target={"#Order_" + idx}>
                                                                <img
                                                                    src="https://www.flexdaycdn.net/public/images/ticket_store/order_arrow_blue.png"
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                        </div>
                                                        <div id={"Order_"+idx} className="order_contbox collapse">
                                                            <div className="order_cont_menulist">
                                                                <div className="title">
                                                                    주문 메뉴
                                                                </div>
                                                                {row.smartOrderLines.map((line,lineIndex)=>(
                                                                    <div className="prd_info">
                                                                        <div className="prd_name">
                                                                            {line.productName}
                                                                        </div>
                                                                        <div className="prd_count">
                                                                            {line.countOfOrder+("개")}
                                                                        </div>
                                                                    </div>

                                                                ))}
                                                            </div>
                                                            <div className="order_cont_infobox">
                                                                <div className="orderbox">
                                                                    <p className="title">
                                                                        주문 정보
                                                                    </p>
                                                                    <div className="cont d-flex">
                                                                        <div className="sub">
                                                                            주문 일시
                                                                        </div>
                                                                        <div className="desc">
                                                                            {tableDateFormat(row.acceptRequestedAt)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            )
                                        )


                                    }

                            </div>
                        }
                        </div>
        </>
    );
}
