import {useCallback, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Spinner} from "react-bootstrap";

import Modal from 'react-bootstrap/Modal';

import "./TSCommon.css";
import "./TSStore.css";
import {
    AlertError, AlertMessage,
    getApiUrl,
    maskingName,
    maskingPhoneNumber,
    setCommas,
    tableDateFormat
} from "../../function/common";
import axios from "axios";
import PaperTicketAddModal from "./component/PaperTicketAddModal";
import TimeChecker from "./component/TimeChecker";
import {toast} from "react-toastify";
import PullToRefresh from "react-simple-pull-to-refresh";

export default function TSStoreNew({waiting, apiReLoad}) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    // 주문거절
    const [showCancelModal, setShowCancelModalState] = useState(false);
    const setShowCancelModal = useCallback((bool) =>{
        setShowCancelModalState(bool)
    },[])
    const closeCancelModal = () => setShowCancelModal(false);
    const openCancelModal = () => setShowCancelModal(true);
    const toastText = useRef();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const errorMessage = useRef();



    const [selectedItems, setSelectedItems] = useState([]);

    const handleClickCss = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };


    async function OrderSuss(smartOrderId){
        
        let obj = {
            smartOrderId : smartOrderId
        }
        axios.post(
            getApiUrl("v1/kitchen/me/smart-orders/"+smartOrderId), 
            // {params : obj},
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    apiReLoad();
                }
            }
        ).catch(function(error){
            if(error.response && error.response.data && error.response.data.code ==-76){
                errorMessage.current = '다른 태블릿 기기에서 이미 처리된 주문입니다.';
                setShowErrorModal(true);
                apiReLoad();
            }else{
                errorMessage.current = AlertMessage(error);
                setShowErrorModal(true);
                apiReLoad();
            }
        });
    }

    const Orderfail = async () => {

        let Tmp = document.querySelector('input[name="orderRefuse"]:checked').value;
        toastText.current = "주문번호 "+selectedOrderNumber.current+" - "+Tmp+"의 사유로 주문을 거절하였습니다"
        let obj = {
            "reason": Tmp
        }
        
        const response = await axios.delete(
            getApiUrl("v1/kitchen/me/smart-orders/"+selectedSmartOrderId.current),
            {data:obj},
            axios.defaults.headers.common['ContentType'] = "application/json;",
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
        ).then(
            function(response){
                console.log(response)
                if(response.status === 200){
                    toast(toastText.current);
                    apiReLoad();
                    closeCancelModal();
                }
            }
        ).catch(function(error){
            console.log(error);
            closeCancelModal();
            AlertError(error);
        })
    }

    const selectedSmartOrderId = useRef(0);
    const selectedOrderNumber = useRef(0);

    function clickCancel(smartOrderId, orderNumber){
        selectedSmartOrderId.current = smartOrderId;
        selectedOrderNumber.current = orderNumber
        openCancelModal();
    }




    if (loading)
        return (
            <div className="container" style={{ position: "absolute", left: "50%", top: "50%", marginLeft: "-18px" }}>
                <Spinner animation="border" />
            </div>
        );

    return (
        <>


                        {/* 신규 주문 없는 경우 */}
                        {/* 임시로 d-none 추가해서 숨김처리 */}

                        {/*<div className="ContentsWrap no-order">*/}
                        {/*    */}
                        {/*    <PaperTicketAddModal></PaperTicketAddModal>*/}
                        {/*    {showToast && <ToastModal setToast={setShowToast} text={toastText.current}/>}*/}
                        {/*</div>*/}



                        <div className={waiting && waiting.totalCount==0?"ContentsWrap no-order":"ContentsWrap"}>

                        {/* 신규 주문 있는 경우 */}
                        {waiting && waiting.totalCount==0?
                            <div className="no_order_txt">
                            신규 주문이 없습니다
                            </div>
                            :

                            <div className="order_list d-flex">

                                {
                                    waiting && waiting.data.map( (row, idx) => (
                                        row.smartOrderType.code === 1 ?

                                            <div className={selectedItems.includes(row)?'order_item':'order_item collapsed'} key={row.orderNumber} >
                                            {/*<div key={row.orderNumber} className="order_item collapsed">*/}
                                                <div className="order_top d-flex">
                                                    {/*<div className="order_titbox d-flex collapsed" data-bs-toggle="collapse"*/}
                                                    <div className={selectedItems.includes(row)?"order_titbox d-flex collapsed":"order_titbox d-flex"} data-bs-toggle="collapse"
                                                         onClick={(state) => handleClickCss(row)}
                                                        data-bs-target={"#Order_"+idx}>
                                                        <div className="order_num">
                                                            주문번호
                                                            <span className="num">{row.orderNumber}</span>
                                                        </div>
                                                        <div className="order_name">
                                                            {row.orderName}
                                                        </div>
                                                    </div>

                                                    <div className="order_statebox d-flex">
                                                        <div className="order_time">
                                                            <TimeChecker targetDate={row.acceptRequestedAt}/>
                                                        </div>
                                                        <div className="order_btnbox d-flex">
                                                            <button type="button" className="btn btn-secondary2 order_refuse" onClick={()=>clickCancel(row.smartOrderId, row.orderNumber)}>
                                                                거절
                                                            </button>
                                                            <button type="button" className="btn  btn-default2 order_accept" onClick={() => { OrderSuss(row.smartOrderId) }}>
                                                                수락
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {/*<div className="order_arrow" data-bs-toggle="collapse" data-bs-target={"#Order_"+idx}>*/}
                                                    <div className="order_arrow" onClick={(state) => handleClickCss(row)}
                                                         data-bs-toggle="collapse" data-bs-target={"#Order_" + idx}>
                                                        <img src="https://www.flexdaycdn.net/public/images/ticket_store/order_arrow_blue.png"
                                                            alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div id={"Order_"+idx} className="order_contbox collapse">
                                                    <div className="order_cont_menulist">
                                                        <div className="title">
                                                            주문 메뉴
                                                        </div>
                                                        <div className="prd_info">
                                                            <div className="prd_name">
                                                                {row.orderName}
                                                            </div>
                                                            <div className="prd_count">
                                                                1개
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="order_cont_infobox">
                                                        <div className="orderbox">
                                                            <p className="title">
                                                                주문 정보
                                                            </p>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    주문 일시
                                                                </div>
                                                                <div className="desc">
                                                                    {tableDateFormat(row.acceptRequestedAt)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="paybox">
                                                            <p className="title">
                                                                결제 정보
                                                            </p>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    결제 일시
                                                                </div>
                                                                <div className="desc">
                                                                    {tableDateFormat(row.paidAt)}
                                                                </div>
                                                            </div>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    결제 금액
                                                                </div>
                                                                <div className="desc">
                                                                    {setCommas(row.paymentPrice)+"원"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="userbox">
                                                            <p className="title">
                                                                주문자 정보
                                                            </p>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    이름
                                                                </div>
                                                                <div className="desc">
                                                                    {maskingName(row.customerName==null?row.nonUserCustomerName:row.customerName)}
                                                                </div>
                                                            </div>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    연락처
                                                                </div>
                                                                <div className="desc">
                                                                    {maskingPhoneNumber(row.phoneNumber==null?row.nonUserPhoneNumber:row.phoneNumber)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :

                                            // <div className="order_item collapsed">
                                             <div className={selectedItems.includes(row)?'order_item':'order_item collapsed'} key={row.orderNumber} >
                                                <div className="order_top d-flex ">
                                                    {/*<div className="order_titbox d-flex collapsed" data-bs-toggle="collapse"*/}
                                                   <div className={selectedItems.includes(row)?"order_titbox d-flex collapsed":"order_titbox d-flex"} data-bs-toggle="collapse"
                                                        onClick={(state) => handleClickCss(row)}
                                                        data-bs-target={"#Order_" + idx}>
                                                        <div className="order_num">
                                                            주문번호
                                                            <span className="num">{row.orderNumber}</span>
                                                        </div>
                                                        <div className="order_device">
                                                            <img src="https://www.flexdaycdn.net/public/images/ticket_store/tag.png"
                                                                alt="" className="img-fluid" />
                                                            <div className="txt">
                                                                식권 발매기에서 결제된 상품입니다
                                                            </div>
                                                        </div>
                                                        <div className="order_name">
                                                            {row.orderName}
                                                        </div>
                                                    </div>
                                                    <div className="order_statebox d-flex">
                                                        <div className="order_time">
                                                            <TimeChecker targetDate={row.acceptRequestedAt}/>
                                                        </div>
                                                        <div className="order_btnbox d-flex">
                                                            {/* <button type="button" className="btn btn-secondary2 order_refuse" onClick={()=>clickCancel(row.smartOrderId,row.orderNumber)}>
                                                                거절
                                                            </button> */}
                                                            <button type="button" className="btn btn-default2 order_accept" onClick={() => { OrderSuss(row.smartOrderId) }}>
                                                                조리시작
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="order_arrow" onClick={(state) => handleClickCss(row)}
                                                         data-bs-toggle="collapse" data-bs-target={"#Order_" + idx}>
                                                        <img src="https://www.flexdaycdn.net/public/images/ticket_store/order_arrow_blue.png"
                                                            alt="" className="img-fluid" />
                                                    </div>
                                                </div>

                                                <div id={"Order_"+idx} className="order_contbox collapse">
                                                    <div className="order_cont_menulist">
                                                        <div className="title">
                                                            주문 메뉴
                                                        </div>
                                                        {row.smartOrderLines.map((line,lineIndex)=>(
                                                            <div className="prd_info">
                                                                <div className="prd_name">
                                                                    {line.productName}
                                                                </div>
                                                                <div className="prd_count">
                                                                    {line.countOfOrder+("개")}
                                                                </div>
                                                            </div>

                                                        ))}
                                                    </div>
                                                    <div className="order_cont_infobox">
                                                        <div className="orderbox">
                                                            <p className="title">
                                                                주문 정보
                                                            </p>
                                                            <div className="cont d-flex">
                                                                <div className="sub">
                                                                    주문 일시
                                                                </div>
                                                                <div className="desc">
                                                                    {tableDateFormat(row.acceptRequestedAt)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    ))
                                }

                            {/*</div>*/}
                        </div>
                        }

                            <PaperTicketAddModal apiReload={apiReLoad}/>
                            <button onClick={()=>apiReLoad()} className="btn btn-clear paperTicket_add" style={{left:'16%'}}>
                                새로고침
                            </button>
                        </div>


                    {/* 주문 거절  Modal */}
                    <Modal
                    show={showCancelModal}
                    onHide={closeCancelModal}
                    keyboard={false}
                    size="sm"
                    className="demoModal TSto"
                    id="modal_orderRefuse"
                    centered
                    >            
                         <Modal.Header className="text-center">
                            <Modal.Title>
                                주문 거절 사유
                            </Modal.Title>
                        </Modal.Header> 

                        <img alt="" src="https://www.flexdaycdn.net/public/images/ticket_store/modal-close.png"
                        className="img-fluid modal_close" onClick={closeCancelModal}/>

                        <Modal.Body className="pb-4-5 text-center">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="orderRefuse" id="orderRefuse_opt1" value="재료 소진" />
                                <label className="form-check-label" htmlFor="orderRefuse_opt1">
                                    재료 소진
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="orderRefuse" id="orderRefuse_opt2" value="매장 마감" />
                                <label className="form-check-label" htmlFor="orderRefuse_opt2">
                                    매장 마감
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="orderRefuse" id="orderRefuse_opt3" value="긴급 보수" />
                                <label className="form-check-label" htmlFor="orderRefuse_opt3">
                                    긴급 보수
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="orderRefuse" id="orderRefuse_opt4" value="기타" />
                                <label className="form-check-label" htmlFor="orderRefuse_opt4">
                                    기타
                                </label>
                            </div>
                        </Modal.Body>                       
                        <Modal.Footer className="d-flex flex-nowrap">
                            <button type="button" className="btn btn-clear btn-modal-primary w-100" onClick={() => {Orderfail()} }>
                                선택 완료
                            </button>
                        </Modal.Footer>
                    </Modal>


            <Modal
                show={showErrorModal}
                onHide={()=>setShowErrorModal(false)}
                keyboard={false}
                size="sm"
                className="demoModal TSto"
                centered
            >
                <Modal.Body className="pb-4-5 text-center">
                </Modal.Body>
                <Modal.Header className="text-center">
                    <Modal.Title>
                        {errorMessage.current}
                        {/*다른 태블릿 기기에서 이미 처리된 주문입니다.*/}
                        {/*<br/>새로고침 버튼을 눌러주세요.*/}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer className="d-flex flex-nowrap">
                    <button type="button" className="btn btn-clear btn-modal-primary w-100" onClick={()=>setShowErrorModal(false)}>
                        확인
                    </button>
                </Modal.Footer>
            </Modal>


        </>
    );
}
