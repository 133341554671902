import { getApiUrl, getday, getTime } from "../../../function/common";
import { useEffect, useRef, useState, version } from "react";
import HeaderTime from "./HeaderTime";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CommonHeader({ myKitchen, setMyKitchen }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showLogout, setShowLogout] = useState(false);
  const handleLogoutClose = () => setShowLogout(false);
  const handleLogoutShow = () => setShowLogout(true);
  const navigate = useNavigate();

  function stopKitchen() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .post(
          getApiUrl("v1/kitchen/me/stop"),
          { params: null },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setMyKitchen(response.data);
          }
        })
        .catch(function (error) {
          console.log("error");
        });
    });
  }

  function logoutKitchen() {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  }

  return (
    <>
      <div className="StoHeader">
        <div className="left d-flex">
          <div className="name">
            {/*인제대학교 다솜식당 A 배식구*/}
            {myKitchen.kitchenName}
          </div>
          <div className="ver">ver {process.env.REACT_APP_VERSION}</div>
          <div className="date sub_txt">
            <HeaderTime />
          </div>
        </div>
        <div className="right d-flex">
          <div className="contact sub_txt">
            고객센터 051-621-0203 (10:00~18:00)
          </div>
          {myKitchen && myKitchen.isOpen == true ? (
            <button
              className="btn btn-clear btn-storeClose"
              onClick={handleShow}
            >
              주방 종료
            </button>
          ) : (
            <>
              <button className="btn btn-clear btn-storeClose off">
                주방 종료
              </button>
              <button
                className="btn btn-clear btn-storeClose"
                style={{ margin: "10px" }}
                onClick={handleLogoutShow}
              >
                로그 아웃
              </button>
            </>
          )}
        </div>
      </div>

      {/* 태블릿 종료  Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="sm"
        className="demoModal TSto"
        centered
      >
        <Modal.Body className="pb-4-5 text-center">
          태블릿을 종료하시면 <br />
          사용자가 주문을 할 수 없습니다
        </Modal.Body>
        <Modal.Header className="text-center">
          <Modal.Title>태블릿을 종료하시겠습니까?</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="d-flex flex-nowrap">
          <button
            type="button"
            className="btn btn-clear btn-modal-secondary w-50"
            onClick={handleClose}
          >
            취소
          </button>
          <div className="bar"></div>
          <button
            type="button"
            className="btn btn-clear btn-modal-primary w-50"
            onClick={() => {
              handleClose();
              stopKitchen();
            }}
          >
            확인
          </button>
        </Modal.Footer>
      </Modal>

      {/* 태블릿 로그아웃  Modal */}
      <Modal
        show={showLogout}
        onHide={handleLogoutClose}
        keyboard={false}
        size="sm"
        className="demoModal TSto"
        centered
      >
        <Modal.Body className="pb-4-5 text-center">
          {/*태블릿을 종료하시면 <br/>*/}
          {/*사용자가 주문을 할 수 없습니다*/}
        </Modal.Body>
        <Modal.Header className="text-center">
          <Modal.Title>태블릿을 로그아웃 하시겠습니까?</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="d-flex flex-nowrap">
          <button
            type="button"
            className="btn btn-clear btn-modal-secondary w-50"
            onClick={handleLogoutClose}
          >
            취소
          </button>
          <div className="bar"></div>
          <button
            type="button"
            className="btn btn-clear btn-modal-primary w-50"
            onClick={() => {
              handleLogoutClose();
              logoutKitchen();
            }}
          >
            확인
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
