import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Spinner} from "react-bootstrap";

import "./TSCommon.css";
import "./TSStore.css";
import {maskingName, maskingPhoneNumber, setCommas, tableDateFormat} from "../../function/common";

export default function TSStoreRefuse({cancelled,apiReLoad}) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    // 태블릿 종료


    const [selectedItems, setSelectedItems] = useState([]);

    const handleClickCss = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };



    if (loading)
        return (
            <div className="container" style={{position: "absolute", left: "50%", top: "50%", marginLeft: "-18px"}}>
                <Spinner animation="border"/>
            </div>
        );

    return (
        <>


                            {/* 주문 거절 없는 경우 */}
                            {/* 임시로 d-none 추가해서 숨김처리 */}
                            {cancelled && cancelled.totalCount == 0 &&
                                <div className="ContentsWrap no-order">
                                    <div className="no_order_txt">
                                        거절된 주문이 없습니다
                                    </div>
                                </div>
                            }

                            {/* 주문 거절 있는 경우 */}
                            {cancelled && cancelled.totalCount == 0 ? "" :
                                <div className="ContentsWrap">
                                    <div className="order_list d-flex">
                                        {
                                            cancelled && cancelled.data.map((row, idx) => (
                                                    row.smartOrderType.code === 1 ?
                                                        <div
                                                            className={selectedItems.includes(row) ? 'order_item' : 'order_item collapsed'}
                                                            key={row.orderNumber}>
                                                            <div className="order_top d-flex ">
                                                                <div
                                                                    className={selectedItems.includes(row) ? "order_titbox d-flex collapsed" : "order_titbox d-flex"}
                                                                    data-bs-toggle="collapse"
                                                                    onClick={(state) => handleClickCss(row)}
                                                                    data-bs-target={"#Order_" + idx}>
                                                                    <div className="order_num">
                                                                        주문번호
                                                                        <span className="num">{row.orderNumber}</span>
                                                                    </div>
                                                                    <div className="order_name">
                                                                        {row.orderName}
                                                                    </div>
                                                                </div>

                                                                <div className="order_statebox d-flex">
                                                                    <div className="order_time">
                                                                        {row.cancelReason}
                                                                    </div>
                                                                </div>
                                                                <div className="order_arrow"
                                                                     onClick={(state) => handleClickCss(row)}
                                                                     data-bs-toggle="collapse" data-bs-target={"#Order_" + idx}>
                                                                    <img
                                                                        src="https://www.flexdaycdn.net/public/images/ticket_store/order_arrow_blue.png"
                                                                        alt="" className="img-fluid"/>
                                                                </div>
                                                            </div>
                                                            <div id={"Order_" + idx} className="order_contbox collapse">
                                                                <div className="order_cont_menulist">
                                                                    <div className="title">
                                                                        주문 메뉴
                                                                    </div>
                                                                    <div className="prd_info">
                                                                        <div className="prd_name">
                                                                            {row.orderName}
                                                                        </div>
                                                                        <div className="prd_count">
                                                                            1개
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="order_cont_infobox">
                                                                    <div className="orderbox">
                                                                        <p className="title">
                                                                            주문 정보
                                                                        </p>
                                                                        <div className="cont d-flex">
                                                                            <div className="sub">
                                                                                주문 일시
                                                                            </div>
                                                                            <div className="desc">
                                                                                {tableDateFormat(row.acceptRequestedAt)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="paybox">
                                                                        <p className="title">
                                                                            결제 정보
                                                                        </p>
                                                                        <div className="cont d-flex">
                                                                            <div className="sub">
                                                                                결제 일시
                                                                            </div>
                                                                            <div className="desc">
                                                                                {tableDateFormat(row.paidAt)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="cont d-flex">
                                                                            <div className="sub">
                                                                                결제 금액
                                                                            </div>
                                                                            <div className="desc">
                                                                                {setCommas(row.paymentPrice)+"원"}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="userbox">
                                                                        <p className="title">
                                                                            주문자 정보
                                                                        </p>
                                                                        <div className="cont d-flex">
                                                                            <div className="sub">
                                                                                이름
                                                                            </div>
                                                                            <div className="desc">
                                                                                {maskingName(row.customerName==null?row.nonUserCustomerName:row.customerName)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="cont d-flex">
                                                                            <div className="sub">
                                                                                연락처
                                                                            </div>
                                                                            <div className="desc">
                                                                                {maskingPhoneNumber(row.phoneNumber==null?row.nonUserPhoneNumber:row.phoneNumber)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={selectedItems.includes(row)?'order_item':'order_item collapsed'} key={row.orderNumber} >
                                                            <div className="order_top d-flex ">
                                                                <div className={selectedItems.includes(row)?"order_titbox d-flex collapsed":"order_titbox d-flex"} data-bs-toggle="collapse"
                                                                     onClick={(state) => handleClickCss(row)}
                                                                     data-bs-target={"#Order_" + idx}>
                                                                    <div className="order_num">
                                                                        주문번호
                                                                        <span className="num">{row.orderNumber}</span>
                                                                    </div>
                                                                    <div className="order_device">
                                                                        <img
                                                                            src="https://www.flexdaycdn.net/public/images/ticket_store/tag.png"
                                                                            alt="" className="img-fluid"/>
                                                                        <div className="txt">
                                                                            식권 발매기에서 결제된 상품입니다
                                                                        </div>
                                                                    </div>
                                                                    <div className="order_name">
                                                                        {row.orderName}
                                                                    </div>
                                                                </div>
                                                                <div className="order_statebox d-flex">
                                                                    <div className="order_time">
                                                                        {row.cancelReason}
                                                                    </div>
                                                                </div>
                                                                <div className="order_arrow" onClick={(state) => handleClickCss(row)}
                                                                     data-bs-toggle="collapse" data-bs-target={"#Order_" + idx}>
                                                                    <img
                                                                        src="https://www.flexdaycdn.net/public/images/ticket_store/order_arrow_blue.png"
                                                                        alt="" className="img-fluid"/>
                                                                </div>
                                                            </div>
                                                            <div id={"Order_"+idx} className="order_contbox collapse">
                                                                <div className="order_cont_menulist">
                                                                    <div className="title">
                                                                        주문 메뉴
                                                                    </div>
                                                                    {row.smartOrderLines.map((line,lineIndex)=>(
                                                                        <div className="prd_info">
                                                                            <div className="prd_name">
                                                                                {line.productName}
                                                                            </div>
                                                                            <div className="prd_count">
                                                                                {line.countOfOrder+("개")}
                                                                            </div>
                                                                        </div>

                                                                    ))}
                                                                </div>
                                                                <div className="order_cont_infobox">
                                                                    <div className="orderbox">
                                                                        <p className="title">
                                                                            주문 정보
                                                                        </p>
                                                                        <div className="cont d-flex">
                                                                            <div className="sub">
                                                                                주문 일시
                                                                            </div>
                                                                            <div className="desc">
                                                                                {tableDateFormat(row.acceptRequestedAt)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            }

        </>
    );
}
