import {useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Spinner} from "react-bootstrap";

import "./TSCommon.css";
import "./TSStore.css";
import {
    AlertError,
    getApiUrl,
    maskingName,
    maskingPhoneNumber,
    setCommas,
    tableDateFormat
} from "../../function/common";
import axios from "axios";
import TimeChecker from "./component/TimeChecker";
import {toast} from "react-toastify";

export default function TSStoreProcee({cooking,apiReLoad}) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);
    const toastText = useRef();
    const selectedSmartOrderId = useRef(0);
    const selectedOrderNumber = useRef(0);

    const handleClickCss = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };


    function requestToPickup(smartOrderId, orderNumber){
        selectedSmartOrderId.current = smartOrderId;
        selectedOrderNumber.current = orderNumber
        toastText.current = "주문번호 "+selectedOrderNumber.current+" - 픽업을 요청 하였습니다."

        axios.post(
            getApiUrl("v1/kitchen/me/smart-orders/"+smartOrderId+"/pickup"),
            // {params : obj},
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    toast(toastText.current)
                    apiReLoad();
                    // navigate('/tspick');
                }
            }
        ).catch(function(error){
            AlertError(error);
        });

    }

    function completeToPickup(smartOrderId, orderNumber){
        selectedSmartOrderId.current = smartOrderId;
        selectedOrderNumber.current = orderNumber
        axios.post(
            getApiUrl("v1/kitchen/me/smart-orders/"+smartOrderId+"/pickup-complete"),
            // {params : obj},
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    toast(toastText.current);
                    apiReLoad();
                    // navigate('/tspick?isDefault=false')
                }
            }
        ).catch(function(error){
            AlertError(error);
        });

    }


    if (loading)
        return (
            <div className="container" style={{ position: "absolute", left: "50%", top: "50%", marginLeft: "-18px" }}>
                <Spinner animation="border" />
            </div>
        );

    return (
        <>


                        <div className={cooking && cooking.totalCount==0?"ContentsWrap no-order":"ContentsWrap"}>
                         {/*진행 주문 있는 경우 */}
                        {cooking && cooking.totalCount == 0 ?
                            <div className="no_order_txt">
                                조리중인 주문이 없습니다.
                            </div>
                            :

                                <div className="order_list d-flex">
                                    {
                                        cooking && cooking.data.map( (row, idx) => (
                                            row.smartOrderType.code === 1 ?

                                                <div className={selectedItems.includes(row)?'order_item':'order_item collapsed'} key={row.orderNumber} >
                                                    <div className="order_top d-flex ">
                                                        <div className={selectedItems.includes(row)?"order_titbox d-flex collapsed":"order_titbox d-flex"} data-bs-toggle="collapse"
                                                             onClick={(state) => handleClickCss(row)}
                                                             data-bs-target={"#Order_"+idx}>
                                                            <div className="order_num">
                                                                주문번호
                                                                <span className="num">{row.orderNumber}</span>
                                                            </div>
                                                            <div className="order_name">
                                                                {row.orderName}
                                                            </div>
                                                        </div>
                                                        <div className="order_statebox d-flex">
                                                            <div className="order_time">
                                                                <TimeChecker targetDate={row.acceptRequestedAt}/>
                                                            </div>

                                                            <div className="order_btnbox d-flex">
                                                                <button type="button" className="btn btn-default2 order_pickup" onClick={()=>requestToPickup(row.smartOrderId, row.orderNumber)}>
                                                                    조리완료
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="order_arrow" onClick={(state) => handleClickCss(row)}
                                                             data-bs-toggle="collapse" data-bs-target={"#Order_" + idx}>
                                                            <img src="https://www.flexdaycdn.net/public/images/ticket_store/order_arrow_blue.png"
                                                                 alt="" className="img-fluid" />
                                                        </div>
                                                    </div>

                                                    <div id={"Order_"+idx} className="order_contbox collapse">

                                                        <div className="order_cont_menulist">
                                                            <div className="title">
                                                                주문 메뉴
                                                            </div>
                                                            <div className="prd_info">
                                                                <div className="prd_name">
                                                                    {row.orderName}
                                                                </div>
                                                                <div className="prd_count">
                                                                    1개
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="order_cont_infobox">
                                                            <div className="orderbox">
                                                                <p className="title">
                                                                    주문 정보
                                                                </p>
                                                                <div className="cont d-flex">
                                                                    <div className="sub">
                                                                        주문 일시
                                                                    </div>
                                                                    <div className="desc">
                                                                        {tableDateFormat(row.acceptRequestedAt)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="paybox">
                                                                <p className="title">
                                                                    결제 정보
                                                                </p>
                                                                <div className="cont d-flex">
                                                                    <div className="sub">
                                                                        결제 일시
                                                                    </div>
                                                                    <div className="desc">
                                                                        {tableDateFormat(row.paidAt)}
                                                                    </div>
                                                                </div>
                                                                <div className="cont d-flex">
                                                                    <div className="sub">
                                                                        결제 금액
                                                                    </div>
                                                                    <div className="desc">
                                                                        {setCommas(row.paymentPrice)+"원"}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="userbox">
                                                                <p className="title">
                                                                    주문자 정보
                                                                </p>
                                                                <div className="cont d-flex">
                                                                    <div className="sub">
                                                                        이름
                                                                    </div>
                                                                    <div className="desc">
                                                                        {maskingName(row.customerName==null?row.nonUserCustomerName:row.customerName)}
                                                                    </div>
                                                                </div>
                                                                <div className="cont d-flex">
                                                                    <div className="sub">
                                                                        연락처
                                                                    </div>
                                                                    <div className="desc">
                                                                        {maskingPhoneNumber(row.phoneNumber==null?row.nonUserPhoneNumber:row.phoneNumber)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                <div className={selectedItems.includes(row)?'order_item':'order_item collapsed'} key={row.orderNumber} >
                                                    <div className="order_top d-flex">
                                                        <div className={selectedItems.includes(row)?"order_titbox d-flex collapsed":"order_titbox d-flex"} data-bs-toggle="collapse"
                                                             onClick={(state) => handleClickCss(row)}
                                                             data-bs-target={"#Order_" + idx}>
                                                            <div className="order_num">
                                                                주문번호
                                                                <span className="num">{row.orderNumber}</span>
                                                            </div>
                                                            <div className="order_device">
                                                                <img
                                                                    src="https://www.flexdaycdn.net/public/images/ticket_store/tag.png"
                                                                    alt="" className="img-fluid"/>
                                                                <div className="txt">
                                                                    식권 발매기에서 결제된 상품입니다
                                                                </div>
                                                            </div>
                                                            <div className="order_name">
                                                                {row.orderName}
                                                            </div>
                                                        </div>

                                                        <div className="order_statebox d-flex">
                                                            <div className="order_time">
                                                                <TimeChecker targetDate={row.acceptRequestedAt}/>
                                                            </div>
                                                            <div className="order_btnbox d-flex">
                                                                <button type="button" className="btn btn-secondary3 order_pickdone" onClick={()=> completeToPickup(row.smartOrderId,row.orderNumber)}>
                                                                    조리완료
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="order_arrow" onClick={(state) => handleClickCss(row)}
                                                             data-bs-toggle="collapse" data-bs-target={"#Order_" + idx}>
                                                            <img
                                                                src="https://www.flexdaycdn.net/public/images/ticket_store/order_arrow_blue.png"
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                    </div>

                                                    <div id={"Order_"+idx} className="order_contbox collapse">
                                                        <div className="order_pickup_infotxt">
                                                            조리완료 시, 대면으로 픽업 안내해주시고
                                                            <span className="bold">픽업이 완료되면 우측의 [조리완료] 버튼</span>을
                                                            눌러주세요
                                                        </div>
                                                        <div className="order_cont_menulist">
                                                            <div className="title">
                                                                주문 메뉴
                                                            </div>
                                                            {row.smartOrderLines.map((line,lineIndex)=>(
                                                                <div className="prd_info">
                                                                    <div className="prd_name">
                                                                        {line.productName}
                                                                    </div>
                                                                    <div className="prd_count">
                                                                        {line.countOfOrder+("개")}
                                                                    </div>
                                                                </div>

                                                            ))}
                                                        </div>
                                                        <div className="order_cont_infobox">
                                                            <div className="orderbox">
                                                                <p className="title">
                                                                    주문 정보
                                                                </p>
                                                                <div className="cont d-flex">
                                                                    <div className="sub">
                                                                        주문 일시
                                                                    </div>
                                                                    <div className="desc">
                                                                        {tableDateFormat(row.acceptRequestedAt)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        ))
                                    }

                            </div>
                        }
                        </div>

        </>
    );
}
